import React, { useContext } from "react";
import "./Home.css";
import Goals from "../../Goals/Goals";
import Sponsors from "../../Sponsors/Sponsors";
import FinancialList from "../../FinancialList/FinancialList";
import Criteria from "../../Criteria/Criteria";
import TopSection from "../../TopSection/TopSection";
import { LanguageContext } from "../../../App";
import { Link } from "react-router-dom";

function Home() {
  const language = useContext(LanguageContext);
  return (
    <div className="home">
      {language === "arabic" ? (
        <TopSection
          title={"جمعية شدا للرياضات الجبلية​"}
          content={"كن جزءا من جمعية شدا وانضم إلينا"}
          linkTitle={"انضم إلينا"}
          news="آخر الأخبار"
        />
      ) : (
        <TopSection
          title={"Shada Mountain Sports Association"}
          content={"Be part of Shada Association and join us"}
          linkTitle={"Join us"}
          news="Latest News"
        />
      )}
    </div>
  );
}

export default Home;
