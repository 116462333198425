import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import About from "../Sections/About/About";
// import Projects from "./Projects/Projects";
// import Services from "./Services/Services";
// import Commitment from "./Commitment/Commitment";
// import Gallery from "../Sections/Gallery/Gallery";
// import Memberships from "../Memberships/Memberships";
// import Criteria from "../Criteria/Criteria";
// import FinancialList from "../FinancialList/FinancialList";
// import Future from "../Future/Future";
// import ParallelList from "../ParallelList/ParallelList";
// import Sponsors from "../Sponsors/Sponsors";
// import Administrative from "../Administrative/Administrative";
// import AddmAss from "../AdmAssocia/AddmAss";
// import Executive from "../Executive/Executive";
// import Members from "../Members/Members";
// import Contact from "../Sections/Contact/Contact";
// import Hiring from "../Hiring/Hiring";
// import Volunteer from "../Volunteer/Volunteer";
// import Complains from "../Complains/Complains";
// import NestedAbout from "../NestedAbout/NestedAbout";
// import Courses from "../Sections/Courses/Courses";
// import Community from "../Community/Community";
// import Market from "../Market/Market";
import Home from "./Home/Home";

const LazyLatestNews = React.lazy(() => import("./LatestNews/LatestNews"));
const LazyMarket = React.lazy(() => import("../Market/Market"));
const LazyCourses = React.lazy(() => import("../Sections/Courses/Courses"));
const LazyNestedAbout = React.lazy(() => import("../NestedAbout/NestedAbout"));
const LazyComplains = React.lazy(() => import("../Complains/Complains"));
const LazyVolunteer = React.lazy(() => import("../Volunteer/Volunteer"));
const LazyHiring = React.lazy(() => import("../Hiring/Hiring"));
const LazyContact = React.lazy(() => import("../Sections/Contact/Contact"));
const LazyMembers = React.lazy(() => import("../Members/Members"));
const LazyExecutive = React.lazy(() => import("../Executive/Executive"));
const LazyAdmAssocia = React.lazy(() => import("../AdmAssocia/AddmAss"));
const LazyAdministrative = React.lazy(() =>
  import("../Administrative/Administrative")
);
const LazySponsors = React.lazy(() => import("../Sponsors/Sponsors"));
const LazyParallelList = React.lazy(() =>
  import("../ParallelList/ParallelList")
);
const LazyFuture = React.lazy(() => import("../Future/Future"));
const LazyFinancialList = React.lazy(() =>
  import("../FinancialList/FinancialList")
);
const LazyCriteria = React.lazy(() => import("../Criteria/Criteria"));
const LazyMemberships = React.lazy(() => import("../Memberships/Memberships"));
const LazyGallery = React.lazy(() => import("../Sections/Gallery/Gallery"));
const LazyCommitment = React.lazy(() => import("./Commitment/Commitment"));
const LazyServices = React.lazy(() => import("./Services/Services"));
const LazyProjects = React.lazy(() => import("./Projects/Projects"));
const LazyAbout = React.lazy(() => import("../Sections/About/About"));

function Sections() {
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className="sections">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="about"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyAbout />
            </Suspense>
          }
        >
          <Route
            path="administrative"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyAdministrative />
              </Suspense>
            }
          />
          <Route
            path="add-members"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyAdmAssocia />
              </Suspense>
            }
          />
          <Route
            path="executive"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyExecutive />
              </Suspense>
            }
          />
          <Route
            path="members"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyMembers />
              </Suspense>
            }
          />
          <Route
            path="nested"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyNestedAbout />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="projects"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyProjects />
            </Suspense>
          }
        />
        <Route
          path="community"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyLatestNews />
            </Suspense>
          }
        />
        <Route
          path="latest-news"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyLatestNews />
            </Suspense>
          }
        />
        <Route
          path="courses"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyCourses />
            </Suspense>
          }
        />
        <Route
          path="services"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyServices />
            </Suspense>
          }
        >
          <Route
            path="sponsors"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazySponsors />
              </Suspense>
            }
          />
          <Route
            path="memberships"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyMemberships />
              </Suspense>
            }
          />
          <Route
            path="store"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyMarket />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="contact"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyContact />
            </Suspense>
          }
        >
          <Route
            path="hiring"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyHiring />
              </Suspense>
            }
          />
          <Route
            path="volunteer"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyVolunteer />
              </Suspense>
            }
          />
          <Route
            path="complains"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyComplains />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="commitment"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyCommitment />
            </Suspense>
          }
        >
          <Route
            path="criteria"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyCriteria />
              </Suspense>
            }
          />
          <Route
            path="financial-list"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyFinancialList />
              </Suspense>
            }
          />
          <Route
            path="future-plan"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyFuture />
              </Suspense>
            }
          />
          <Route
            path="parallel-list"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <LazyParallelList />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="gallery"
          element={
            <Suspense fallback={<div>loading ...</div>}>
              <LazyGallery />
            </Suspense>
          }
        />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
}

export default Sections;
