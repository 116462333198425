import React, { useState } from "react";
import "./Sponsors.css";
import SmallTitle from "../SmallTitle/SmallTitle";
import FD from "../../images/Silver.svg";
import gold from "../../images/Golden.svg";
import platinium from "../../images/Platinum.svg";
import bronzy from "../..//images/Bronze.svg";
import normal from "../../images/logo.svg";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

function ArabicSponsors() {
  return (
    <div className="sponsors">
      <SmallTitle title={"الرعاة ​"} />
      <div className="all-sponsors-box flex flex-wrap justify-center items-center gap-9">
        <div className="sponsors-box text-end shadow-lg ">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={normal} alt="normal" />
          </div>
          <div className="title">
            <h1> رعاية عادية​</h1>
          </div>
          {/* <div className="description">
            <p>عضوية فخريه</p>
            <p> درع فاخر على الظهر</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg ">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={bronzy} alt="bronzy" />
          </div>
          <div className="title">
            <h1> رعاية برونزية​</h1>
          </div>
          {/* <div className="description">
            <p>عضوية محترف</p>
            <p>درع ذهبي</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg ">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={FD} alt="bronzy" />
          </div>
          <div className="title">
            <h1>رعاية فضية​</h1>
          </div>
          {/* <div className="description">
            <p>عضوية هاوي</p>
            <p> درع فضي</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg ">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={gold} alt="bronzy" />
          </div>
          <div className="title">
            <h1> رعاية ذهبية</h1>
          </div>
          {/* <div className="description">
            <p>عضوية هاوي</p>
            <p>عضوتين عادية</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg ">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={platinium} alt="bronzy" />
          </div>
          <div className="title">
            <h1>رعاية بلاتينية ​</h1>
          </div>
          {/* <div className="description">
            <p>العضوية الممنوحة</p>
            <p>هدايا</p>
          </div> */}
        </div>
      </div>
      <div className="info-table">
        <table style={{ direction: "rtl" }}>
          <thead>
            <th>
              {" "}
              <td></td>{" "}
            </th>
            <th>
              {" "}
              <td>
                <img src={bronzy} alt="bronzy" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={FD} alt="FD" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={gold} alt="gold" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={platinium} alt="gold" />
              </td>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>السعر السنوي (الريال)</td>
              <td>25.000</td>
              <td>50.000</td>
              <td>75,000</td>
              <td>125,000</td>
            </tr>
            <tr>
              <td>العضوية الممنوحة</td>
              <td>
                عضوية هاوي <br />
                عضوتين عادية
              </td>
              <td>
                {" "}
                عضوية هاوي <br /> عضوية عادية
              </td>
              <td>
                عضوية محترف
                <br /> عضوية هاوي
              </td>
              <td>
                عضوية فخريه
                <br /> عضويات محترف <br /> عضوية هاوي
              </td>
            </tr>
            <tr>
              <td>هدايا</td>
              <td>
                <IoClose />
              </td>
              <td>
                درع فضي
                <br /> تيشيرت الجمعية
              </td>
              <td>
                درع ذهبي
                <br /> تيشيرت الجمعية <br />
                بطاقة مميزة
              </td>
              <td>
                درع فاخر
                <br /> تيشيرت الجمعية
                <br /> بطاقة مميزة
                <br /> هدية تذكارية
              </td>
            </tr>
            <tr>
              <td>تواجد في المطبوعات الرسمية</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
            </tr>
            <tr>
              <td>تواجد في ملابس الجمعية</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>على الأكتاف </td>
              <td>على الظهر</td>
            </tr>
            <tr>
              <td>تسمية فعالية باسم الراعي</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>حسب الأنظمة</td>
            </tr>
            <tr>
              <td>تكريم في الحفل السنوي</td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ArabicSponsors;
