import React, { useContext, useState } from "react";
import "./TopSection.css";
import { LanguageContext } from "../../App";
import ArabicTopSection from "./ArabicTopSection";
import EnglishTopSection from "./EnglishTopSection";

function TopSection({ title, content, linkTitle, news }) {
  const language = useContext(LanguageContext);
  return (
    <div>
      {language === "arabic" ? (
        <ArabicTopSection
          title={title}
          content={content}
          linkTitle={linkTitle}
          news={news}
        />
      ) : (
        <EnglishTopSection
          title={title}
          content={content}
          linkTitle={linkTitle}
          news={news}
        />
      )}
    </div>
  );
}

export default TopSection;
