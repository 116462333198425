import React from "react";
import "./About.css";

import TopSection from "../../TopSection/TopSection";
import { Link, Outlet, useLocation } from "react-router-dom";

function ArabicAbout() {
  const location = useLocation();
  return (
    <div className="about text-end">
      {/* <TopSection
        title={"جمعية شدا للرياضات الجبلية​"}
        content="جمعية شدا للرياضات الجبلية هي منظمة رائدة مكرسة لتعزيز وتطوير الرياضات الجبلية في منطقة الباحة. تحمل اسم جبال شدا الشامخة، تهدف الجمعية إلى زيادة الوعي، وتعزيز ثقافة حيوية للرياضات الجبلية، وتفعيل الأنشطة الرياضية المختلفة التي تبرز الجمال الطبيعي والتضاريس المميزة لمنطقة الباحة"
      />
      <button>
        <Link
          to="/latest-news"
          className={`${
            location.pathname.includes("news") ? "!text-[black]" : ""
          }`}
        >
          آخر الأخبار
        </Link>
      </button> */}
      <Outlet />
    </div>
  );
}

export default ArabicAbout;
