import React, { useContext } from "react";
import "./TopSection.css";
import deepSpace from "../../videos/deep space.webm";
import { LanguageContext } from "../../App";
import { Link, useLocation } from "react-router-dom";
import Typewriter from "../TypeWriter/TypeWriter";

function EnglishTopSection({ title, content, linkTitle, news }) {
  const location = useLocation();
  return (
    <div className="top-section overflow-hidden">
      <video src={deepSpace} className="video-back" autoPlay loop muted />
      <div className="above ">
        <div
          className={`${
            location.pathname === "/"
              ? "info !left-0 !right-[auto] !rounded-s-none !rounded-e-[114px]"
              : "info"
          }`}
        >
          <h1
            className={`${
              location.pathname === "/"
                ? "title lg:!text-[60px] !text-[40px] "
                : "title lg:!text-[60px] !text-[40px]"
            }`}
          >
            {location.pathname === "/" ? (
              <Typewriter text={title} delay={100} />
            ) : (
              title
            )}
          </h1>
          <p
            className={`${
              location.pathname === "/"
                ? "!right-[auto] top-[240px] md:top-[0] !translate-x-[-50%] left-[60%] lg:left-[145%] xl:left-[170%] !w-[370px] !text-[20px] md:!text-[23px] md:!w-[465px]"
                : ""
            }`}
          >
            {content}
            <div className="flex items-center justify-center">
              <Link to="/services/memberships">{linkTitle}</Link>
              <Link to="/latest-news"> {news} </Link>
            </div>
          </p>
        </div>
        {/* <div className="social-media-icon">
          <motion.div
            initial={{ left: "-187px" }}
            whileHover={{ left: "0" }}
            transition={{ duration: 0.3 }}
            className="facebook icon-cover gap-3 bg-[#3b5998]"
          >
            <p>Follow us on Facebook</p>
            <a className="icon w-8 h-8">
              <RiFacebookCircleLine />
            </a>
          </motion.div>
          <motion.div
            initial={{ left: "-187px" }}
            whileHover={{ left: "0" }}
            transition={{ duration: 0.3 }}
            className="instagram icon-cover gap-3 bg-[#125688]"
          >
            <p>Follow us on Instagram </p>
            <a className="icon w-8 h-8">
              <RiInstagramLine />
            </a>
          </motion.div>
          <motion.div
            initial={{ left: "-187px" }}
            whileHover={{ left: "0" }}
            transition={{ duration: 0.3 }}
            className="X icon-cover gap-3 bg-[#55acee]"
          >
            <p>Follow us on X </p>
            <a className="icon w-8 h-8">
              <RiTwitterXFill />
            </a>
          </motion.div>
          <motion.div
            initial={{ left: "-187px" }}
            whileHover={{ left: "0" }}
            transition={{ duration: 0.3 }}
            className="linkedin icon-cover gap-3 bg-[blue]"
          >
            <p> Follow us on Linkedin </p>
            <a className="icon w-8 h-8">
              <RiLinkedinFill />
            </a>
          </motion.div>
        </div> */}
      </div>
    </div>
  );
}

export default EnglishTopSection;
