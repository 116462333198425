import React, { useContext, useState } from "react";
import "./TopSection.css";
import deepSpace from "../../videos/deep space.webm";
import { Link, useLocation } from "react-router-dom";
import Typewriter from "../TypeWriter/TypeWriter";

function ArabicTopSection({ title, content, linkTitle, news }) {
  const location = useLocation();
  return (
    <div className="top-section overflow-hidden relative">
      <video src={deepSpace} className="video-back" autoPlay loop muted />
      <div className="above ">
        <div className="info">
          <h1 className="title">
            {location.pathname === "/" ? (
              <Typewriter text={title} delay={100} />
            ) : (
              title
            )}
          </h1>
          <p>
            {content}
            <div className="flex items-center justify-center">
              <Link to="/services/memberships">{linkTitle}</Link>
              <Link to="/latest-news"> {news} </Link>
            </div>
          </p>
        </div>

        {/* <div className="social-media-icon right-0 overflow-hidden">
          <motion.div
            initial={{ right: "-137px" }}
            whileHover={{ right: "0" }}
            transition={{ duration: 0.3 }}
            className="facebook icon-cover gap-3 bg-[#3b5998] !justify-end flex-row-reverse"
          >
            <p> تابعنا على فيسبوك</p>
            <a className="icon w-8 h-8">
              <RiFacebookCircleLine />
            </a>
          </motion.div>
          <motion.div
            initial={{ right: "-137px" }}
            whileHover={{ right: "0" }}
            transition={{ duration: 0.3 }}
            className="instagram icon-cover gap-3 bg-[#125688] !justify-end flex-row-reverse"
          >
            <p>تابعنا على إنستغرام</p>
            <a className="icon w-8 h-8">
              <RiInstagramLine />
            </a>
          </motion.div>
          <motion.div
            initial={{ right: "-137px" }}
            whileHover={{ right: "0" }}
            transition={{ duration: 0.3 }}
            className="X icon-cover gap-3 bg-[#55acee] !justify-end flex-row-reverse"
          >
            <p> تابعنا على منصة</p>
            <a className="icon w-8 h-8">
              <RiTwitterXFill />
            </a>
          </motion.div>
          <motion.div
            initial={{ right: "-137px" }}
            whileHover={{ right: "0" }}
            transition={{ duration: 0.3 }}
            className="linkedin icon-cover gap-3 bg-[blue] !justify-end flex-row-reverse"
          >
            <p>تابعنا على لينكدن</p>
            <a className="icon w-8 h-8">
              <RiLinkedinFill />
            </a>
          </motion.div>
        </div> */}
      </div>
    </div>
  );
}

export default ArabicTopSection;
