import React from "react";
import "./Sponsors.css";
import SmallTitle from "../SmallTitle/SmallTitle";
import FD from "../../images/Silver.svg";
import gold from "../../images/Golden.svg";
import platinium from "../../images/Platinum.svg";
import bronzy from "../..//images/Bronze.svg";
import normal from "../../images/logo.svg";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

function EnglishSponsors() {
  return (
    <div className="sponsors">
      <SmallTitle title={"Sponsors ​"} />
      <div className="all-sponsors-box flex flex-wrap justify-center items-center gap-9">
        <div className="sponsors-box text-end shadow-lg">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={normal} alt="normal" />
          </div>
          <div className="title">
            <h1 className="!text-[24px]"> Regular sponsorship</h1>
          </div>
          {/* <div className="description">
            <p>Honorary Membership.</p>
            <p> Luxury Shield on Back.</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={bronzy} alt="bronzy" />
          </div>
          <div className="title">
            <h1 className="!text-[24px]"> Bronze sponsorship</h1>
          </div>
          {/* <div className="description">
            <p>Professional Membership.</p>
            <p>Gold Shield.</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={FD} alt="bronzy" />
          </div>
          <div className="title">
            <h1 className="!text-[24px]">Silver sponsorship</h1>
          </div>
          {/* <div className="description">
            <p>Amateur Membership.</p>
            <p> Silver Shield.</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={gold} alt="bronzy" />
          </div>
          <div className="title">
            <h1 className="!text-[24px]">Golden sponsorship</h1>
          </div>
          {/* <div className="description">
            <p>Amateur Membership.</p>
            <p>Regular Membership.</p>
          </div> */}
        </div>
        <div className="sponsors-box text-end shadow-lg">
          <div className="icon">
            <img className="w-[120px] h-[120px]" src={platinium} alt="bronzy" />
          </div>
          <div className="title">
            <h1 className="!text-[24px]">Platinum sponsorship ​</h1>
          </div>
          {/* <div className="description">
            <p>Membership Granted.</p>
            <p>Gifts.</p>
          </div> */}
        </div>
      </div>
      <div className="info-table">
        <table style={{ direction: "ltr" }}>
          <thead>
            <th>
              {" "}
              <td></td>{" "}
            </th>
            <th>
              {" "}
              <td>
                <img src={bronzy} alt="bronzy" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={FD} alt="FD" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={gold} alt="gold" />
              </td>
            </th>
            <th>
              {" "}
              <td>
                <img src={platinium} alt="gold" />
              </td>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Annual price (riyal)</td>
              <td>25.000</td>
              <td>50.000</td>
              <td>75,000</td>
              <td>125,000</td>
            </tr>
            <tr>
              <td>Membership granted</td>
              <td>
                Amateur Membership. <br />2 Regular Memberships.
              </td>
              <td>
                {" "}
                Amateur Membership.
                <br />
                Regular Membership.
              </td>
              <td>
                Professional Membership.
                <br /> Amateur Membership.
              </td>
              <td>
                Honorary Membership.
                <br />
                Professional Memberships.
                <br />
                Amateur Membership.
              </td>
            </tr>
            <tr>
              <td>Gifts</td>
              <td>
                <IoClose />
              </td>
              <td>
                Silver Shield.
                <br /> Association T-Shirt.
              </td>
              <td>
                Gold Shield.
                <br /> Association T-shirt.
                <br /> Special Card.
              </td>
              <td>
                Luxury Shield.
                <br /> Association T-shirt.
                <br /> Special Card.
                <br /> Souvenir.
              </td>
            </tr>
            <tr>
              <td>Presence in official publications</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
            </tr>
            <tr>
              <td>Presence in the association's clothing</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>On the shoulders.</td>
              <td>On the back.</td>
            </tr>
            <tr>
              <td>Name an event after the sponsor</td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>
                <IoClose />
              </td>
              <td>According to the regulations.</td>
            </tr>
            <tr>
              <td>Honoring at the annual ceremony</td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
              <td>
                <FaCheck />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EnglishSponsors;
