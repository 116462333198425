import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./socialMediaLinks";
import {
  RiInstagramLine,
  RiLinkedinFill,
  RiTiktokFill,
  RiTwitterXFill,
} from "react-icons/ri";

class socialMediaLinks extends Component {
  render() {
    return (
      <div className="social-media-links">
        <Link className="X" to="about">
          <RiTwitterXFill />
        </Link>
        <Link className="instagram" to="about">
          <RiInstagramLine />
        </Link>
        <Link className="linkedin" to="about">
          <RiLinkedinFill />
        </Link>
        <Link className="tik-tok" to="about">
          <RiTiktokFill />
        </Link>
      </div>
    );
  }
}

export default socialMediaLinks;
