import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo1.png";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { LanguageContext, setLanguageContext } from "../../App";

function EnglishHeader() {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [id, setId] = useState(null);
  const [locationPath, setLocationPath] = useState("");
  const setLanguage = useContext(setLanguageContext);
  const language = useContext(LanguageContext);

  useEffect(() => {
    setShowNavbar(false);
    setLocationPath(location.pathname.slice(0, 5));
  }, [location.pathname]);

  useEffect(() => {
    goToZero();
  }, [locationPath]);

  const setLocalStorageValue = (e) => {
    const set = localStorage.setItem("language", e);
  };

  const setFunctions = (e) => {
    setLocalStorageValue(e);
    setLanguage(e);
  };

  const getLocalStorageValue = () => {
    const currentLanguage = localStorage.getItem("language");
    setLanguage(currentLanguage);
  };

  window.onload = () => {
    getLocalStorageValue();
  };

  const sideBarFunction = (idx) => {
    setId(idx);
    setActive(!active);
  };

  const goToZero = () => {
    console.log("iuiuiu");

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  // const goUnderTopSection = () => {
  //   window.scroll({
  //     top: 500,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <div className="fixed header">
      <div className="language fixed p-2 top-[8px] right-[65px] md:!top-[50px] xl:!right-[5px] lg:!left-[auto] outline-none z-[999999] bg-[white] rounded-lg text-[black]">
        <button
          className={`capitalize ${
            language === "arabic" ? "text-[#a04028]" : ""
          }`}
          onClick={() => setFunctions("arabic")}
        >
          العربية
        </button>
        <strong className="mx-[5px] font-[100]">/</strong>
        <button
          className={`capitalize ${
            language === "english" ? "text-[#a04028]" : ""
          }`}
          onClick={() => setFunctions("english")}
        >
          english
        </button>
      </div>
      <div className="inner-header flex items-center justify-left ">
        <div className="logo cursor-pointer" onClick={() => navigate("/")}>
          <img className="" src={logo} alt="logo" />
        </div>
        <ul className="list flex ">
          <li>
            <Link
              to="/"
              className={`${
                location.pathname === "/"
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Home
            </Link>
          </li>
          <li
            onMouseEnter={(() => setHover(true), () => setId(0))}
            onMouseLeave={(() => setHover(false), () => setId(null))}
          >
            <Link
              to="#"
              className={`${
                location.pathname.includes("about")
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
            >
              About {id === 0 ? <HiChevronUp /> : <HiChevronDown />}
            </Link>
            <div className="nested-list">
              <div className="links !items-end">
                <Link
                  onClick={() => goToZero()}
                  to="about/nested"
                  className={`${
                    location.pathname.includes("nested")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  About
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/add-members"
                  className={`${
                    location.pathname.includes("add-members")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Board Members
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/executive"
                  className={`${
                    location.pathname.includes("executive")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  } `}
                >
                  {" "}
                  Executive Team
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/members"
                  className={`${
                    location.pathname.includes("/members")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Association Members
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/administrative"
                  className={`${
                    location.pathname.includes("administrative")
                      ? " !text-start !justify-end  active"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  structure of the association
                </Link>
              </div>
            </div>
          </li>
          <li>
            <Link
              to="gallery"
              className={`${
                location.pathname.includes("gallery")
                  ? "!text-[black]"
                  : " !text-start !justify-end"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Gallery
            </Link>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !justify-end active"
                : " !text-start !justify-end"
            }`}
          >
            <Link
              to="projects"
              className={`${
                location.pathname.includes("projects")
                  ? "!text-[black]"
                  : " !text-start !justify-end"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Projects
            </Link>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !justify-end active"
                : " !text-start !justify-end"
            }`}
          >
            <Link
              to="community"
              className={`${
                location.pathname.includes("community")
                  ? "!text-[black]"
                  : " !text-start !justify-end"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Shada community
            </Link>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !justify-end active"
                : " !text-start !justify-end"
            }`}
          >
            <Link
              to="courses"
              className={`${
                location.pathname.includes("courses")
                  ? "!text-[black]"
                  : " !text-start !justify-end"
              }`}
              onClick={() => goToZero()}
            >
              Courses and training
            </Link>
          </li>
          <li
            onMouseEnter={(() => setHover(true), () => setId(1))}
            onMouseLeave={(() => setHover(false), () => setId(null))}
          >
            {" "}
            <Link
              to="#"
              className={`${
                location.pathname.includes("commitment")
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
            >
              {" "}
              Governance and Compliance{" "}
              {id === 1 ? <HiChevronUp /> : <HiChevronDown />}{" "}
            </Link>{" "}
            <div className="nested-list">
              {" "}
              <div className="links !items-end">
                {" "}
                <Link
                  onClick={() => goToZero()}
                  to="commitment/parallel-list"
                  className={`${
                    location.pathname.includes("parallel-list")
                      ? " !text-start !justify-end active"
                      : " !text-start !justify-end"
                  }`}
                >
                  Financial Budget{" "}
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/financial-list"
                  className={`${
                    location.pathname.includes("financial-list")
                      ? " !text-start !justify-end active"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Financial Statements
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/future-plan"
                  className={`${
                    location.pathname.includes("future-plan")
                      ? " !text-start !justify-end active"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Future Plan
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/criteria"
                  className={`${
                    location.pathname.includes("criteria")
                      ? " !text-start !justify-end active"
                      : " !text-start !justify-end"
                  }`}
                >
                  Governance and Compliance Standard
                </Link>
              </div>
            </div>
          </li>
          <li
            onMouseEnter={(() => setHover(true), () => setId(2))}
            onMouseLeave={(() => setHover(false), () => setId(null))}
          >
            {" "}
            <Link
              to="#"
              className={`${
                location.pathname.includes("services")
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
            >
              {" "}
              Electronic services{" "}
              {id === 2 ? <HiChevronUp /> : <HiChevronDown />}{" "}
            </Link>{" "}
            <div className="nested-list">
              {" "}
              <div className="links !items-end">
                {" "}
                <Link
                  onClick={() => goToZero()}
                  to="services/store"
                  className={`${
                    location.pathname.includes("store")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Store{" "}
                </Link>{" "}
                <Link
                  onClick={() => goToZero()}
                  to="services/sponsors"
                  className={`${
                    location.pathname.includes("sponsors")
                      ? "active !text-start !justify-end"
                      : "!text-start !justify-end"
                  }`}
                >
                  {" "}
                  Sponsors{" "}
                </Link>{" "}
                <Link
                  onClick={() => goToZero()}
                  to="services/memberships"
                  className={`${
                    location.pathname.includes("memberships")
                      ? "active !text-start !justify-end"
                      : "!text-start !justify-end"
                  }`}
                >
                  {" "}
                  Memberships{" "}
                </Link>{" "}
              </div>{" "}
            </div>{" "}
          </li>
          <li
            onMouseEnter={(() => setHover(true), () => setId(3))}
            onMouseLeave={(() => setHover(false), () => setId(null))}
          >
            {" "}
            <Link
              to="#"
              className={`${
                location.pathname.includes("contact")
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
            >
              {" "}
              Contact us​ {id === 3 ? <HiChevronUp /> : <HiChevronDown />}{" "}
            </Link>{" "}
            <div className="nested-list">
              {" "}
              <div className="links !items-end">
                {" "}
                <Link
                  onClick={() => goToZero()}
                  to="contact/complains"
                  className={`${
                    location.pathname.includes("complains")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Suggestions and Complaints
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="contact/hiring"
                  className={`${
                    location.pathname.includes("hiring")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Recruitment
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="contact/volunteer"
                  className={`${
                    location.pathname.includes("volunteer")
                      ? "active !text-start !justify-end"
                      : " !text-start !justify-end"
                  }`}
                >
                  {" "}
                  Volunteering
                </Link>
              </div>
            </div>
          </li>
        </ul>
        <div
          onClick={() => setShowNavbar(!showNavbar)}
          className="sidebar-icon w-10 h-10"
        >
          <span className="w-10 h-10 block cursor-pointer">
            {showNavbar ? <IoClose /> : <IoReorderThreeOutline />}
          </span>
        </div>
      </div>
      <div
        className={`side-bar-header ${
          showNavbar
            ? "opacity-100 duration-1000 overflow-hidden"
            : "opacity-0 h-0 duration-1000 overflow-hidden"
        }`}
      >
        <ul className="list flex gap-6">
          <li>
            <Link
              to="/"
              className={`${
                location.pathname === "/"
                  ? "!text-[black] !flex-row"
                  : "!flex-row"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Home
            </Link>
          </li>
          <li
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => sideBarFunction(0)}
            className={`${id === 0 && active ? "active" : ""}`}
          >
            <Link
              to="#"
              className={`${
                location.pathname.includes("about")
                  ? "!text-[black] !flex-row capitalize"
                  : " !text-start  !flex-row capitalize"
              }`}
            >
              about
              {id === 0 ? <HiChevronUp /> : <HiChevronDown />}
            </Link>
            <div className="nested-list">
              <div className="links !items-end">
                <Link
                  onClick={() => goToZero()}
                  to="about/nested"
                  className={`${
                    location.pathname.includes("goals")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  About
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/add-members"
                  className={`${
                    location.pathname.includes("add-members")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Board Member
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/executive"
                  className={`${
                    location.pathname.includes("executive")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Executive Team
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/members"
                  className={`${
                    location.pathname.includes("/members")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Association Members
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="about/administrative"
                  className={`${
                    location.pathname.includes("administrative")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Administrative Structure of the Association
                </Link>
              </div>
            </div>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !flex-row capitalize active"
                : " !text-start !flex-row capitalize"
            }`}
          >
            <Link
              to="gallery"
              className={`${
                location.pathname.includes("gallery")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Gallery
            </Link>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !flex-row capitalize active"
                : " !text-start !flex-row capitalize"
            }`}
          >
            <Link
              to="projects"
              className={`${
                location.pathname.includes("projects")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Projects
            </Link>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !flex-row capitalize active"
                : " !text-start !flex-row capitalize"
            }`}
          >
            <Link
              to="community"
              className={`${
                location.pathname.includes("community")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Shada Community
            </Link>
          </li>
          <li
            onClick={() => sideBarFunction(1)}
            className={`${id === 1 && active ? "active" : ""}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Link
              to="#"
              className={`${
                location.pathname.includes("commitment")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
            >
              {" "}
              Governance and Compliance{" "}
              {id === 1 ? <HiChevronUp /> : <HiChevronDown />}
            </Link>
            <div className="nested-list">
              <div className="links !items-end">
                <Link
                  onClick={() => goToZero()}
                  to="commitment/parallel-list"
                  className={`${
                    location.pathname.includes("parallel-list")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  Financial Budget{" "}
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/financial-list"
                  className={`${
                    location.pathname.includes("financial-list")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Financial Statements
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/future-plan"
                  className={`${
                    location.pathname.includes("future-plan")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Future Plan
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="commitment/criteria"
                  className={`${
                    location.pathname.includes("criteria")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  Standard of Governance and Compliance
                </Link>
              </div>
            </div>
          </li>
          <li
            onClick={() => sideBarFunction(2)}
            className={`${id === 2 && active ? "active" : ""}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Link
              to="#"
              className={`${
                location.pathname.includes("services")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
            >
              {" "}
              Services {id === 2 ? <HiChevronUp /> : <HiChevronDown />}
            </Link>
            <div className="nested-list">
              <div className="links !items-end">
                <Link
                  onClick={() => goToZero()}
                  to="services/store"
                  className={`${
                    location.pathname.includes("store")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Store{" "}
                </Link>{" "}
                <Link
                  onClick={() => goToZero()}
                  to="services/sponsors"
                  className={`${
                    location.pathname.includes("sponsors")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Sponsors
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="services/memberships"
                  className={`${
                    location.pathname.includes("memberships")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Memberships
                </Link>
              </div>
            </div>
          </li>
          <li
            onClick={() => sideBarFunction(3)}
            className={`${id === 3 && active ? "active" : ""}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Link
              to="#"
              className={`${
                location.pathname.includes("contact")
                  ? "!text-[black] !flex-row"
                  : " !text-start !flex-row capitalize"
              }`}
            >
              {" "}
              Contact us {id === 3 ? <HiChevronUp /> : <HiChevronDown />}
            </Link>
            <div className="nested-list">
              <div className="links !items-end">
                <Link
                  onClick={() => goToZero()}
                  to="contact/complains"
                  className={`${
                    location.pathname.includes("complains")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Suggestions and complaints
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="contact/hiring"
                  className={`${
                    location.pathname.includes("hiring")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Employment
                </Link>
                <Link
                  onClick={() => goToZero()}
                  to="contact/volunteer"
                  className={`${
                    location.pathname.includes("volunteer")
                      ? "active !text-start !flex-row capitalize"
                      : " !text-start !flex-row capitalize"
                  }`}
                >
                  {" "}
                  Volunteering
                </Link>
              </div>
            </div>
          </li>
          <li
            onClick={() => setActive(!active)}
            className={`${
              active
                ? " !text-start !flex-row capitalize active"
                : " !text-start !flex-row capitalize"
            }`}
          >
            <Link
              to="latest-news"
              className={`${
                location.pathname.includes("news")
                  ? "!text-[black] !flex-row capitalize"
                  : " !text-start !flex-row capitalize"
              }`}
              onClick={() => goToZero()}
            >
              {" "}
              Latest News
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default EnglishHeader;
